<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3>Let’s Talk</h3>
            <div class="whiteBar"></div>
          </div>
          <div class="col-lg-6">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Full Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Enter Name"
                  v-model="name"
                />
                <div class="alert alert-danger" v-if="nameErrorMsg !== ''">
                  {{ nameErrorMsg }}
                </div>
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <div class="alert alert-danger" v-if="emailErrorMsg !== ''">
                  {{ emailErrorMsg }}
                </div>
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                > -->
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Phone</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Phone"
                />
              </div>
              <div class="form-group">
                <label for="message">Example textarea</label>
                <textarea
                  class="form-control"
                  id="message"
                  rows="3"
                  v-model="message"
                ></textarea>
                <div class="alert alert-danger" v-if="messageErrorMsg !== ''">
                  {{ messageErrorMsg }}
                </div>
              </div>
              <!-- <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Check me out</label
                >
              </div> -->
              <div class="form-group">
                <div class="alert alert-success" v-if="apiResponse !== ''">
                  {{ apiResponse }}
                </div>
                <button
                  type="submit"
                  @click.prevent="validate()"
                  class="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <div class="addressInfo">
              <h5>Our Address</h5>
              <h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="29"
                  viewBox="0 0 20 29"
                  fill="none"
                >
                  <path
                    d="M10 13.5714C9.0528 13.5714 8.14439 13.1952 7.47462 12.5254C6.80485 11.8556 6.42857 10.9472 6.42857 10C6.42857 9.0528 6.80485 8.14439 7.47462 7.47462C8.14439 6.80485 9.0528 6.42857 10 6.42857C10.9472 6.42857 11.8556 6.80485 12.5254 7.47462C13.1952 8.14439 13.5714 9.0528 13.5714 10C13.5714 10.469 13.4791 10.9334 13.2996 11.3667C13.1201 11.8 12.857 12.1937 12.5254 12.5254C12.1937 12.857 11.8 13.1201 11.3667 13.2996C10.9334 13.4791 10.469 13.5714 10 13.5714ZM10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 17.5 10 28.5714 10 28.5714C10 28.5714 20 17.5 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0Z"
                    fill="white"
                  />
                </svg>
                Saleem Plaza, Muree Road Rawalpindi
              </h6>
              <h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22.6 24C19.7333 24 16.9391 23.3609 14.2173 22.0827C11.4956 20.8044 9.08444 19.1156 6.984 17.016C4.88356 14.9164 3.19467 12.5053 1.91733 9.78267C0.64 7.06 0.000888889 4.26578 0 1.4C0 1 0.133333 0.666667 0.4 0.4C0.666667 0.133333 1 0 1.4 0H6.8C7.11111 0 7.38889 0.0999999 7.63333 0.3C7.87778 0.5 8.02222 0.755556 8.06667 1.06667L8.93333 5.73333C8.97778 6.04445 8.972 6.328 8.916 6.584C8.86 6.84 8.73244 7.06756 8.53333 7.26667L5.33333 10.5333C6.26667 12.1333 7.43333 13.6333 8.83333 15.0333C10.2333 16.4333 11.7778 17.6444 13.4667 18.6667L16.6 15.5333C16.8 15.3333 17.0613 15.1836 17.384 15.084C17.7067 14.9844 18.0231 14.9564 18.3333 15L22.9333 15.9333C23.2444 16 23.5 16.1502 23.7 16.384C23.9 16.6178 24 16.8898 24 17.2V22.6C24 23 23.8667 23.3333 23.6 23.6C23.3333 23.8667 23 24 22.6 24Z"
                    fill="white"
                  />
                </svg>
                051-4575895
              </h6>
              <h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                >
                  <path
                    d="M2.4 19.2C1.74 19.2 1.1748 18.9648 0.704402 18.4944C0.234002 18.024 -0.000797963 17.4592 2.03735e-06 16.8V2.4C2.03735e-06 1.74 0.235202 1.1748 0.705602 0.704402C1.176 0.234002 1.7408 -0.000797963 2.4 2.03735e-06H21.6C22.26 2.03735e-06 22.8252 0.235202 23.2956 0.705602C23.766 1.176 24.0008 1.7408 24 2.4V16.8C24 17.46 23.7648 18.0252 23.2944 18.4956C22.824 18.966 22.2592 19.2008 21.6 19.2H2.4ZM12 10.59C12.1 10.59 12.2052 10.5748 12.3156 10.5444C12.426 10.514 12.5308 10.4692 12.63 10.41L21.12 5.1C21.28 5 21.4 4.8748 21.48 4.7244C21.56 4.574 21.6 4.4092 21.6 4.23C21.6 3.83 21.43 3.53 21.09 3.33C20.75 3.13 20.4 3.14 20.04 3.36L12 8.4L3.96 3.36C3.6 3.14 3.25 3.1352 2.91 3.3456C2.57 3.556 2.4 3.8508 2.4 4.23C2.4 4.43 2.44 4.6052 2.52 4.7556C2.6 4.906 2.72 5.0208 2.88 5.1L11.37 10.41C11.47 10.47 11.5752 10.5152 11.6856 10.5456C11.796 10.576 11.9008 10.5908 12 10.59Z"
                    fill="white"
                  />
                </svg>
                info@clouds92tech.com
              </h6>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3321.0742255888917!2d73.08009877569953!3d33.65523857330914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDM5JzE4LjkiTiA3M8KwMDQnNTcuNiJF!5e0!3m2!1sen!2s!4v1706376528016!5m2!1sen!2s"
              width="100%"
              height="250"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'ContactForm',
  props: {
    msg: String,
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      nameErrorMsg: '',
      emailErrorMsg: '',
      messageErrorMsg: '',
      apiResponse: '',
    };
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
    validate() {
      let error = 0;
      if (this.name == '') {
        error = error + 1;
        this.nameErrorMsg = 'Name is required.';
        document.getElementById('name').style.border = '1px solid red';
        document.getElementById('name').style.borderRadius = '0px';
      } else {
        this.nameErrorMsg = '';
        document.getElementById('name').style.border = '0px';
        document.getElementById('name').style.borderRadius = '0px';
      }
      if (this.email == '') {
        error = error + 1;
        this.emailErrorMsg = 'Email is required.';
        document.getElementById('email').style.border = '1px solid red';
        document.getElementById('email').style.borderRadius = '0px';
      } else {
        this.emailErrorMsg = '';
        document.getElementById('email').style.border = '0px';
        document.getElementById('email').style.borderRadius = '0px';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
          error = error + 1;
          this.emailErrorMsg = 'Please enter a valid email address';
          document.getElementById('email').style.border = '1px solid red';
          document.getElementById('email').style.borderRadius = '0px';
        } else {
          this.emailErrorMsg = '';
          document.getElementById('email').style.border = '0px';
          document.getElementById('email').style.borderRadius = '0px';
        }
      }

      if (this.message == '') {
        error = error + 1;
        this.messageErrorMsg = 'Message is required.';
        document.getElementById('message').style.border = '1px solid red';
        document.getElementById('message').style.borderRadius = '0px';
      } else {
        this.messageErrorMsg = '';
        document.getElementById('message').style.border = '0px';
        document.getElementById('message').style.borderRadius = '0px';
      }
      if (error == 0) {
        axios
          .post('https://clouds92tech.com/sendEmail.php', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message,
          })
          .then(response => {
            if (response.data == 'success') {
              this.apiResponse =
                'Thanks for contacting us. We will reach to you soon!';
            }
            //this.apiResponse = response.data;
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.addressInfo {
  border-radius: 8px 8px 0 0;
  background: linear-gradient(180deg, #2e38b0 0%, rgba(109, 115, 189, 0) 100%);
  height: 220px;
  h5 {
    text-align: center;
    margin-top: 40px;
    width: 100%;
  }
  h6 {
    width: 350px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    svg {
      margin-right: 20px;
    }
  }
}
.whiteBar {
  width: 120px;
  border-bottom: 4px solid white;
  margin: 0 auto;
  margin-bottom: 20px;
}
.RightDeals {
  background: #070d59;
  padding-bottom: 40px;
  padding-top: 40px;
  .form-group {
    margin-top: 20px;
    label {
      color: #ffffff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    input {
      background: #070d59;
      border: 0px;
      border-bottom: 1px solid #ffffff;
      color: #ffffff;
    }
    textarea {
      background: #070d59;
      border: 0px;
      border-bottom: 1px solid #ffffff;
      color: #ffffff;
    }
    .alert {
      color: red;
      font-weight: 300;
      padding: 5px 10px;
      border-radius: 0px;
    }
  }
  .form-control:focus {
    border-color: rgba(226, 184, 66, 0.91);
    outline: 0;
    box-shadow: none;
  }
  .btn-primary {
    background-color: #fff;
    border-color: #fff;
    width: 100%;
    color: #292341;
    margin-bottom: 80px;
  }
  h3 {
    color: #ffffff;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 40px;
  }
  h4 {
    color: #292341;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h5 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-block;
    margin-left: 20px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #292341;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 24px;
    }
    h5 {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 992px) {
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
</style>
