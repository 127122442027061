<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="mainHeading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="101"
                height="63"
                viewBox="0 0 101 63"
                fill="none"
              >
                <g clip-path="url(#clip0_469_1205)">
                  <path
                    d="M99.271 29.5049C99.271 29.5049 94.7797 20.9413 82.4025 23.8802C82.4033 22.4444 82.2193 21.0136 81.8542 19.6179H82.1284C80.3361 14.4603 75.0858 8.15457 59.9463 12.1444C59.9463 12.1444 49.4035 -7.43488 30.4265 3.13324C27.1088 5.05232 24.4661 7.81918 22.8192 11.0978C21.1723 14.3765 20.592 18.0259 21.1488 21.6031C20.1509 21.6271 19.1568 21.7248 18.1758 21.8951C21.5284 30.2445 27.4745 41.085 37.3847 48.111C48.8553 48.111 60.3891 48.111 71.1217 48.111C73.3568 44.5493 74.2845 39.6448 74.6008 31.7041C74.6008 31.7041 78.2275 40.4039 76.4563 48.1499C82.782 48.1499 87.2732 47.8579 89.677 47.819C90.8132 47.8246 91.9292 47.5418 92.9031 47.0016C96.6141 45.0748 104.437 39.4502 99.271 29.5049Z"
                    fill="#009EEE"
                  />
                  <path
                    d="M7.69651 25.3984C-6.38867 33.7284 3.14201 44.1018 3.14201 44.1018C4.17899 45.4604 5.5079 46.6074 7.04601 47.4713C8.58413 48.3352 10.2985 48.8975 12.0823 49.1232C13.5583 49.24 17.2904 49.3178 22.351 49.3762C20.0912 47.0535 18.0656 44.5454 16.2994 41.8831C12.8838 36.6465 10.0038 31.1278 7.69651 25.3984Z"
                    fill="#009EEE"
                  />
                  <path
                    d="M75.2116 34.9937C75.4014 42.4673 74.1784 45.6397 71.9012 49.2597C65.4701 59.4191 51.3638 55.9742 51.3638 55.9742C45.6999 54.9382 40.3777 52.6919 35.8238 49.4153C25.9135 42.3699 19.9674 31.5488 16.6148 23.1994C15.0097 19.2899 13.7476 15.2677 12.8404 11.1717L16.7835 13.1179L10.7952 2.25781L2.23438 10.3153H6.17743C6.7621 15.4904 8.09527 20.5699 10.1415 25.4182C12.4488 31.1476 15.3289 36.6662 18.7444 41.9029C20.5033 44.5647 22.5218 47.0728 24.7749 49.3959C27.0747 51.759 29.6247 53.9042 32.3868 55.7991C48.6438 66.8538 62.244 61.9297 62.244 61.9297C69.5185 60.3338 72.3651 55.3709 74.5791 49.8631C76.1954 45.043 76.4134 39.9159 75.2116 34.9937Z"
                    fill="#070D59"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_469_1205">
                    <rect width="101" height="63" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              So, Why Us
            </h3>
            <p class="mainPara">
              We are dedicated to the greater cause; Clouds92 Technologies has
              the skills and expertise to provide the support important in
              today's digital economy. We were founded to provide the strong
              technological basis and IT support infrastructure and that
              includes right to privacy and security. We go over checklists to
              truly grasp particular security assessment needs, thanks to an
              expertly skilled workforce and a robust testing methodology. To
              build a strong technological basis for the digital transformation,
              it is important to master cutting-edge technologies like the cloud
              and hybrid systems, security, communications and collaboration,
              and infrastructure.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="row whatWeDoC">
              <div class="col-1 cPointer middleAlign" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#070d59"
                  class="bi bi-chevron-compact-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
                  />
                </svg>
              </div>
              <div class="col-10">
                <div class="row">
                  <div class="col-lg-6" v-for="p in ourPhilosphyC" :key="p">
                    <div class="serviceCard middleAlign2">
                      <img :src="p.img" />
                      <h5>{{ p.heading }}</h5>
                      <p>{{ p.desc }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 cPointer middleAlign" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#070d59"
                  class="bi bi-chevron-compact-rights"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyUs',
  data() {
    return {
      ourPhilosphy: [
        {
          img: require('@/assets/kamran.jpg'),
          heading: 'Mr Kamran, CEO Message',
          desc: 'We develop high-quality, one-of-a-kind that may easily stand out in an incredibly competitive market with trustworthy services. Our developers use cutting-edge technology to develop requirement-specific and user-focused apps that match the needs of the business',
        },
        {
          img: require('@/assets/kamran.jpg'),
          heading: 'Col Asif, Director Message',
          desc: 'Investing in our people is of critical importance as we move forward. Systems Limited believes in reinventing itself every few years, which is why we have grown over the past four decades. der sense and focus on idea generation',
        },
      ],
      ourPhilosphyC: [
        {
          img: require('@/assets/sc1.png'),
          heading: 'Advanced Data Privacy',
          desc: 'We determine how minimal code development can have a substantial impact on the development of business apps. We create projects by writing targeted minimal code applications.',
        },
        {
          img: require('@/assets/sc2.png'),
          heading: 'BPO',
          desc: 'We automate with our comprehensive set of cloud computing infrastructure, application services, application development, and automated solutions, we deliver IT  support fast and affordably.',
        },
      ],
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    var gthis = this;
    setInterval(function () {
      gthis.changeSlide();
    }, 5000);
  },
  methods: {
    changeSlide() {
      //console.log(this.currnetSlideNumber);
      //this.currnetSlideNumber = 'One';
      if (this.currnetSlideNumber == 'One') {
        this.ourPhilosphyC = [
          {
            img: require('@/assets/sc3.png'),
            heading: 'Growth & Scalability',
            desc: 'Our world-class team of expert developers provides services on a large scale and around the clock. We perform tasks with precision using repeatable, scalable, and established techniques for higher efficiency and effectivity.',
          },
          {
            img: require('@/assets/sc4.png'),
            heading: ' Cost- Effective',
            desc: 'Our talent will allow you to streamline your business processes make them more efficient at lower costs. Our talent will allow you to streamline your business processes make them more efficient at lower costs.',
          },
        ];
        this.currnetSlideNumber = 'Two';
      } else {
        this.ourPhilosphyC = [
          {
            img: require('@/assets/sc1.png'),
            heading: 'Advanced Data Privacy',
            desc: 'We determine how minimal code development can have a substantial impact on the development of business apps. We create projects by writing targeted minimal code applications.',
          },
          {
            img: require('@/assets/sc2.png'),
            heading: 'BPO',
            desc: 'We automate with our comprehensive set of cloud computing infrastructure, application services, application development, and automated solutions, we deliver IT  support fast and affordably.',
          },
        ];
        this.currnetSlideNumber = 'One';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.serviceCard {
  background: #070d59;
  min-height: 280px;
  padding: 20px 10px;
  h5 {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 100% */
    padding-top: 10px;
    text-align: center;
  }
  p {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    padding-top: 10px;
    text-align: center;
  }
}
.mainPara {
  color: #070d59;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.middleAlign {
  display: flex;
  justify-content: center;
  flex-direction: row; /* <--- I added this */
  align-items: center; /* <--- Change here */
}
.middleAlign2 {
  display: flex;
  justify-content: center;
  flex-direction: column; /* <--- I added this */
  align-items: center; /* <--- Change here */
}
.middleAlign2 div {
  display: flex;
  flex-direction: column;
}
.whatWeDoC {
  background-color: #070d5922;
  margin-top: 20px;
  padding: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 40px;
}
.GameChanger {
  background: #070d5911;
  padding-bottom: 20px;
  padding-top: 40px;
}
.mainHeading {
  color: #070d59;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin-top: 40px;
  span {
    color: #f46700;
  }
  svg {
    margin-left: 20px;
  }
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
}
</style>
