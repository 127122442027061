<template>
  <div class="Footer">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="logo"><img src="@/assets/logo.png" /></div>
            <p>
              Clouds92 Technologies is a leading IT company. Our comprehensive
              understanding of IT Solutions stems from years of experience
              working in the software development industry.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <a href="#" @click.prevent="goto('Home')">Services</a>
                <div class="subFMenu">
                  <a href="#">Cloud Services</a>
                  <a href="#">Application Development</a>
                  <a href="#">Business Process Outsourcing</a>
                  <a href="#">Data Management& Analytics</a>
                  <a href="#">Search Engine Optimization</a>
                  <a href="#">Digital Services</a>
                  <a href="#">Lead Generation</a>
                  <a href="#">B2B & B2C</a>
                  <a href="#">Business Development</a>
                  <a href="#">Information Technology</a>
                  <a href="#">Marketing Strategies& Solutions</a>
                </div>
              </div>
              <div class="col-lg-6">
                <a href="#" @click.prevent="goto('AboutUs')">Company</a>
                <div class="subFMenu">
                  <a href="#" @click.prevent="goto('AboutUs')">About Us</a>
                  <a href="#" @click.prevent="goto('services')">Services</a>
                  <a href="#">Blog</a>
                  <a href="#" @click.prevent="goto('ContactUs')">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 allRights">
            ALL RIGHTS RESERVED |
            <span @click.prevent="goto('PrivacyPolicy')">PRIVACY POLICY</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCom',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Footer {
  background-color: #070d5918;
  padding-bottom: 20px;
  padding-top: 60px;
  .logo {
    color: #242f9b;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #070d59;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    margin-top: 10px;
  }
  a {
    width: 100%;
    text-align: left;
    text-decoration: none;
    display: block;
    color: #070d59;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0px;
  }
  .subFMenu {
    margin-top: 10px;
    margin-bottom: 5px;
    a {
      text-align: left;
      border-left: 2px solid #070d5966;
      margin-top: 5px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .allRights {
    color: #292341;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 60px;
    span {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 992px) {
  .Footer {
    padding-top: 20px;
    padding-bottom: 10px;
    p {
      width: 100%;
    }
    a {
      text-align: left;
      margin-top: 10px;
    }
  }
}
</style>
