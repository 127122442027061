<template>
  <div class="GameChanger">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="mainHeading">
              Start Today And Be <span>A Game Changer.</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="101"
                height="63"
                viewBox="0 0 101 63"
                fill="none"
              >
                <g clip-path="url(#clip0_469_1205)">
                  <path
                    d="M99.271 29.5049C99.271 29.5049 94.7797 20.9413 82.4025 23.8802C82.4033 22.4444 82.2193 21.0136 81.8542 19.6179H82.1284C80.3361 14.4603 75.0858 8.15457 59.9463 12.1444C59.9463 12.1444 49.4035 -7.43488 30.4265 3.13324C27.1088 5.05232 24.4661 7.81918 22.8192 11.0978C21.1723 14.3765 20.592 18.0259 21.1488 21.6031C20.1509 21.6271 19.1568 21.7248 18.1758 21.8951C21.5284 30.2445 27.4745 41.085 37.3847 48.111C48.8553 48.111 60.3891 48.111 71.1217 48.111C73.3568 44.5493 74.2845 39.6448 74.6008 31.7041C74.6008 31.7041 78.2275 40.4039 76.4563 48.1499C82.782 48.1499 87.2732 47.8579 89.677 47.819C90.8132 47.8246 91.9292 47.5418 92.9031 47.0016C96.6141 45.0748 104.437 39.4502 99.271 29.5049Z"
                    fill="#009EEE"
                  />
                  <path
                    d="M7.69651 25.3984C-6.38867 33.7284 3.14201 44.1018 3.14201 44.1018C4.17899 45.4604 5.5079 46.6074 7.04601 47.4713C8.58413 48.3352 10.2985 48.8975 12.0823 49.1232C13.5583 49.24 17.2904 49.3178 22.351 49.3762C20.0912 47.0535 18.0656 44.5454 16.2994 41.8831C12.8838 36.6465 10.0038 31.1278 7.69651 25.3984Z"
                    fill="#009EEE"
                  />
                  <path
                    d="M75.2116 34.9937C75.4014 42.4673 74.1784 45.6397 71.9012 49.2597C65.4701 59.4191 51.3638 55.9742 51.3638 55.9742C45.6999 54.9382 40.3777 52.6919 35.8238 49.4153C25.9135 42.3699 19.9674 31.5488 16.6148 23.1994C15.0097 19.2899 13.7476 15.2677 12.8404 11.1717L16.7835 13.1179L10.7952 2.25781L2.23438 10.3153H6.17743C6.7621 15.4904 8.09527 20.5699 10.1415 25.4182C12.4488 31.1476 15.3289 36.6662 18.7444 41.9029C20.5033 44.5647 22.5218 47.0728 24.7749 49.3959C27.0747 51.759 29.6247 53.9042 32.3868 55.7991C48.6438 66.8538 62.244 61.9297 62.244 61.9297C69.5185 60.3338 72.3651 55.3709 74.5791 49.8631C76.1954 45.043 76.4134 39.9159 75.2116 34.9937Z"
                    fill="#070D59"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_469_1205">
                    <rect width="101" height="63" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </h3>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartToday',
  props: {
    msg: String,
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.GameChanger {
  background: #070d5915;
  padding-bottom: 20px;
  padding-top: 40px;
}
.mainHeading {
  color: #000;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  span {
    color: #f46700;
  }
  svg {
    margin-left: 20px;
  }
}
@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 992px) {
}
</style>
