<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Why Choose Us</h4>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/sc1.png" />
              <h5>Advanced Data Privacy</h5>
              <p>
                We determine how minimal code development can have a substantial
                impact on the development of business applications. We create
                projects by writing efficient and targeted minimal code
                applications.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/sc2.png" />
              <h5>BPO</h5>
              <p>
                We automate with our comprehensive set of cloud computing
                infrastructure, application services, application development,
                and automated solutions, we deliver IT support fast and
                affordably.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/sc3.png" />
              <h5>Growth & Scalability</h5>
              <p>
                Our world-class team of expert developers provides services on a
                large scale and around the clock. We perform tasks with
                precision using repeatable, scalable, and established techniques
                for higher efficiency.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/sc4.png" />
              <h5>Cost- Effective</h5>
              <p>
                Our talent will allow you to streamline your business processes
                make them more efficient at lower costs. Our talent will allow
                you to streamline your business processes make them more
                efficient at lower costs.
              </p>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutCards',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fourCards {
  background: linear-gradient(
      180deg,
      #053871 3.62%,
      rgba(65, 105, 150, 0.39) 100%
    ),
    #070d59;
  padding-bottom: 40px;
  h4 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }
  h5 {
    color: #ffc93c;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #070d59;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    min-height: 320px;
    img {
      display: inline-block;
      height: 60px;
    }
    h5 {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
      text-align: justify;
      min-height: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .fourCards {
    .zCard {
      p {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .fourCards {
    h4 {
      font-size: 32px;
    }
  }
}
</style>
