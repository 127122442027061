<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Blogs</h4>
          </div>
          <div class="col-lg-4">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/blog1.png" />
              <h5>18 April 2023</h5>
              <p>What is the future of App Modernization in the World?</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="zCard">
              <img src="@/assets/blog2.png" />
              <h5>18 April 2023</h5>
              <p>What is the future of App Modernization in the World?</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="zCard">
              <img src="@/assets/blog3.png" />
              <h5>18 April 2023</h5>
              <p>What is the future of AI Modernization in the World?</p>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogSection',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fourCards {
  padding-bottom: 40px;
  h4 {
    color: #070d59;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    margin-top: 30px;
  }
  h5 {
    color: #ffc93c;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717bcc;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #114d90;
    background: #fffcfc;
    img {
      display: inline-block;
      width: 100%;
      border-radius: 20px 20px 0 0;
    }
    h5 {
      color: #070d59;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      margin: 20px;
      margin-bottom: 0px;
    }
    p {
      color: #070d59;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      margin: 20px;
      margin-top: 0px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1300px) {
  .fourCards {
    .zCard {
      h5 {
        font-size: 24px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .fourCards {
    h4 {
      font-size: 32px;
    }
  }
}
</style>
