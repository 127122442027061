<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 leftSide">
          <h5>What We Do</h5>
          <h6>
            We provide custom services and technology-agnostic solutions that
            transform your ideas into quality products for your business
          </h6>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'ServicesSlider',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.whiteBar {
  width: 120px;
  border-bottom: 4px solid white;
  margin-top: 80px;
  margin-bottom: 80px;
}
.headerBG {
  background: linear-gradient(
    99deg,
    #292341 -5.28%,
    rgba(0, 52, 120, 0.26) 149.21%
  ) !important;
  height: 80px;
  .navbar-brand {
    color: #242f9b;
  }
  .navbar-nav {
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      a {
        color: #242f9b;
      }
      a:hover {
        color: rgba(255, 201, 60, 1);
      }
      a::after {
        color: rgba(255, 201, 60, 1);
      }
    }
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: 26px;
  }

  .dropdown-item {
    color: #fff !important;
    height: 40px;
    line-height: 33px;
  }
  .dropdown-item:hover {
    background-color: #00000040;
    color: rgba(255, 201, 60, 1) !important;
    transition-duration: 0.5s;
  }
  .getQouteA {
    border-radius: 8px;
    border: 3px solid #ffc93c;
    padding: 8px 16px;
    color: #242f9b;
    text-decoration: none;
  }
}
.homeSlide1 {
  background-image: url('@/assets/services.webp');
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80px;
  padding-top: 80px;
  .leftSide {
    h4 {
      color: #070d59;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 4.5px;
      text-transform: capitalize;
      margin-top: 160px;
    }
    h5 {
      color: #070d59;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-align: center;
    }
    h6 {
      color: rgba(7, 13, 89, 0.9);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      margin-top: 10px;
    }
    h3 {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #ffc93c;
      font-family: Inter;
      font-size: 60px;
      font-style: italic;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      margin-top: 80px;
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 40px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 32px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 24px;
      }
      h4 {
        margin-top: 60px;
      }
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 32px;
      }
    }
  }
  .whiteBar {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
