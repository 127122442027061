<template>
  <div class="fourCards">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Our Services</h4>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/do1.png" />
              <h5>Cloud Services</h5>
              <p>
                Our extensive experience in cloud computing enables us to
                develop all-inclusive solutions that are cloud-based tailored to
                business needs.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/do2.png" />
              <h5>BPO</h5>
              <p>
                We automate all of the company procedures by adopting IT
                solutions. With our comprehensive set of cloud computing
                infrastructure, We deliver IT support fast and affordably
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/do3.png" />
              <h5>App Development</h5>
              <p>
                Our expert development team's cutting-edge innovative ideas
                allow them to develop Superior IT solutions that help the
                company's growth.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard">
              <img src="@/assets/do4.png" />
              <h5>Information Technology</h5>
              <p>
                Our expert team keeps new innovation-driven possibilities by
                solving operational effectiveness, growth, and to gain a
                competitive edge and boost output.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/seo.png" />
              <h5>Search Engine Optimization</h5>
              <p>
                Our relationships with skilled professionals who specialize in
                various digital marketing services such as SEO, SMO, PPC, Google
                Ads, online advertising, and so on.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/diigital-services.png" />
              <h5>Digital Services</h5>
              <p>
                We are a Leading Digital Marketing and Search Engine
                Optimization Services to help company stand out in a highly
                competitive industry.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/data-man.png" />
              <h5>Data Management & Analytics</h5>
              <p>
                Our expert development team's cutting-edge innovative ideas
                allow them to develop Superior IT solutions that help the
                company's growth.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/lead-gen.png" />
              <h5>Lead Generation</h5>
              <p>
                As the leading digital marketing agency, we focus on elevating
                the web profile. We offer long-term digital marketing plans that
                generate leads and keep visitors on the website.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/business.png" />
              <h5>Business Development</h5>
              <p>
                We help companies in discovering new opportunities for
                innovation and growth by focusing on operational, growth,
                technological, and operational difficulties.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/b2b.png" />
              <h5>B2B & B2C</h5>
              <p>
                We provide best in class B2B & B2C solutions to clients to
                understand their company's approach and infrastructure before
                suggesting cost-effective and novel IT solutions.
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="zCard" @click="goto('LifeInsurance')">
              <img src="@/assets/marketing.png" />
              <h5>Marketing Strategies & Solutions</h5>
              <p>
                Clouds92 Technologies works persistently to keep aware of
                customer needs and fulfillment in the realms of marketing and
                advertising.
              </p>
            </div>
          </div>
          <!-- <div class="col-lg-12">
            <h4 class="colorWhite">Our Philosophy</h4>
          </div>
          <div class="col-lg-12">
            <div class="row middleAlign">
              <div class="col-1 cPointer" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#fff"
                  class="bi bi-chevron-compact-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"
                  />
                </svg>
              </div>
              <div class="col-10">
                <div
                  class="row middleAlign"
                  v-for="p in ourPhilosphyC"
                  :key="p"
                >
                  <div class="col-lg-3">
                    <img :src="p.img" class="profileImg" />
                  </div>
                  <div class="col-lg-3">
                    <div class="cHeading">{{ p.heading }}</div>
                  </div>
                  <div class="col-lg-6">
                    <p class="cPara">{{ p.desc }}</p>
                  </div>
                </div>
              </div>
              <div class="col-1 cPointer" @click="changeSlide()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#fff"
                  class="bi bi-chevron-compact-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"
                  />
                </svg>
              </div>
            </div>
          </div> -->
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
// import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide, Navigation } from 'vue3-carousel';
export default {
  name: 'ServicesCard',
  props: {
    msg: String,
  },
  data() {
    return {
      ourPhilosphy: [
        {
          img: require('@/assets/kamran.jpg'),
          heading: 'Mr Kamran, CEO Message',
          desc: 'We develop high-quality, one-of-a-kind that may easily stand out in an incredibly competitive market with trustworthy services. Our developers use cutting-edge technology to develop requirement-specific and user-focused apps that match the needs of the business',
        },
        {
          img: require('@/assets/kamran.jpg'),
          heading: 'Col Asif, Director Message',
          desc: 'Investing in our people is of critical importance as we move forward. Systems Limited believes in reinventing itself every few years, which is why we have grown over the past four decades. der sense and focus on idea generation',
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      ourPhilosphyC: [
        {
          img: require('@/assets/kamran.jpg'),
          heading: 'Mr Kamran, CEO Message',
          desc: 'We develop high-quality, one-of-a-kind that may easily stand out in an incredibly competitive market with trustworthy services. Our developers use cutting-edge technology to develop requirement-specific and user-focused apps that match the needs of the business',
        },
      ],
      currnetSlideNumber: 'One',
    };
  },
  components: {
    // Carousel,
    // Slide,
    // Navigation,
  },
  mounted() {
    var gthis = this;
    setInterval(function () {
      gthis.changeSlide();
    }, 5000);
  },
  methods: {
    changeSlide() {
      //console.log(this.currnetSlideNumber);
      //this.currnetSlideNumber = 'One';
      if (this.currnetSlideNumber == 'One') {
        this.ourPhilosphyC = [
          {
            img: require('@/assets/kamran.jpg'),
            heading: 'Col Asif, Director Message',
            desc: 'Investing in our people is of critical importance as we move forward. Systems Limited believes in reinventing itself every few years, which is why we have grown over the past four decades. der sense and focus on idea generation',
          },
        ];
        this.currnetSlideNumber = 'Two';
      } else {
        this.ourPhilosphyC = [
          {
            img: require('@/assets/kamran.jpg'),
            heading: 'Mr Kamran, CEO Message',
            desc: 'We develop high-quality, one-of-a-kind that may easily stand out in an incredibly competitive market with trustworthy services. Our developers use cutting-edge technology to develop requirement-specific and user-focused apps that match the needs of the business',
          },
        ];
        this.currnetSlideNumber = 'One';
      }
    },
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.middleAlign {
  display: flex;
  justify-content: center;
  flex-direction: row; /* <--- I added this */
  align-items: center; /* <--- Change here */
}
.cPointer {
  cursor: pointer;
}
.cPara {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 141.188%; /* 22.59px */
  letter-spacing: 2.56px;
  text-align: left justify;
  width: 100%;
}
.cHeading {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.profileImg {
  width: 220px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}
.carousel__prev,
.carousel__next {
  color: white !important;
}
.carousel__icon {
  fill: red !important;
}
.fourCards {
  // background: linear-gradient(
  //   to bottom,
  //   #070d5918 1%,
  //   #070d5918 41%,
  //   #070d59 41%,
  //   #070d59 100%
  // );
  background-color: #070d5907;
  padding-bottom: 40px;
  h4 {
    color: #070d59;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    span {
      color: #f46700;
    }
  }
  h5 {
    color: #ffc93c;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #ffffff;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 40px !important;
    img {
      display: inline-block;
      height: 50px;
    }
    h5 {
      color: #070d59;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
    }
    p {
      color: #070d59;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.zCard:hover {
  background-color: #070d59;
  transition-duration: 0.5s;
  img {
    filter: brightness(0) invert(1);
    transition-duration: 0.5s;
  }
  h5 {
    color: #ffffff;
    transition-duration: 0.5s;
  }
  p {
    color: #ffffff;
    transition-duration: 0.5s;
  }
}
.colorWhite {
  color: white !important;
}
@media screen and (max-width: 1300px) {
  .fourCards {
    padding-left: 10px;
    padding-right: 10px;
    .zCard {
      margin: 5px;
      h5 {
        font-size: 24px;
      }
      p {
        height: 150px;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .fourCards {
    h4 {
      font-size: 32px;
    }
  }
  .cHeading {
    text-align: center;
  }
}
</style>
