<template>
  <!-- <div class="header bg-light preHdeader">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="hideOnMobile">Speak to a Licensed Sales Agent!</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.48741 10.2428C9.736 12.4384 11.5616 14.264 13.7572 15.5125L14.9365 13.8615C15.3287 13.3127 16.0688 13.1431 16.6608 13.4664C18.5365 14.4911 20.6095 15.1137 22.7719 15.2849C23.4653 15.3399 24 15.9185 24 16.6141V22.5645C24 23.2481 23.4829 23.8209 22.8029 23.8908C22.0964 23.9635 21.3836 24 20.6667 24C9.25279 24 0 14.7472 0 3.33333C0 2.61636 0.0365601 1.90361 0.109187 1.19701C0.179067 0.517 0.75192 0 1.43553 0H7.38589C8.08147 0 8.66017 0.53472 8.71507 1.22812C8.88623 3.39051 9.50893 5.46352 10.5336 7.33925C10.8569 7.9312 10.6873 8.67139 10.1385 9.06343L8.48741 10.2428ZM5.12567 9.36693L7.65893 7.55745C6.94063 6.00685 6.44837 4.36244 6.19636 2.66667H2.67876C2.67071 2.88843 2.66667 3.11067 2.66667 3.33333C2.66667 13.2744 10.7256 21.3333 20.6667 21.3333C20.8893 21.3333 21.1116 21.3293 21.3333 21.3212V17.8036C19.6376 17.5516 17.9932 17.0593 16.4425 16.3411L14.6331 18.8744C13.9011 18.59 13.1941 18.2553 12.5165 17.8748L12.4391 17.8307C9.82627 16.3449 7.65512 14.1737 6.16933 11.5609L6.12525 11.4835C5.74465 10.8059 5.41005 10.0989 5.12567 9.36693Z"
              fill="#FFC93C"
            />
          </svg>
          1-823-653-4721
        </div>
      </div>
    </div>
  </div> -->
  <nav
    class="navbar navbar-expand-lg sticky-top navbar-light bg-light headerBG"
  >
    <div class="container">
      <a class="navbar-brand" href="#" @click.prevent="goto('Home')"
        ><img src="@/assets/logo.png"
      /></a>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar1"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar1">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link text" href="#" @click.prevent="goto('Home')"
              >Home</a
            >
          </li>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Get Insurance
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li> -->
          <li class="nav-item active">
            <a class="nav-link text" href="#" @click.prevent="goto('AboutUs')"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text" href="#" @click.prevent="goto('services')"
              >Services</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text" href="#" @click.prevent="goto('ContactUs')"
              >Contact Us</a
            >
          </li>
          <li class="nav-item">
            <a href="#" class="getQouteA">Get in Touch </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--container-->
</template>

<script>
export default {
  name: 'MainHeader',
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
      setTimeout(function () {
        location.reload();
      }, 100);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.preHdeader {
  background: #242f9b !important;
  color: #fff;
  height: 50px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.headerBG {
  background: rgba(255, 255, 255, 1) !important;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  .navbar-brand {
    color: #242f9b;
    img {
      height: 55px;
      margin-top: -5px;
    }
  }
  .navbar-nav {
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      a {
        color: rgba(0, 0, 0, 1);
        transition-duration: 0.6s;
        text-transform: uppercase;
      }
      a:hover {
        color: #3798d4;
        transition-duration: 0.6s;
      }
      a::after {
        color: #3798d4;
        transition-duration: 0.6s;
      }
      .text {
        margin-top: 0px;
      }
    }
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: 26px;
  }

  .dropdown-item {
    color: #fff !important;
    height: 40px;
    line-height: 33px;
  }
  .dropdown-item:hover {
    background-color: #00000040;
    color: rgba(255, 201, 60, 1) !important;
    transition-duration: 0.5s;
  }
  .getQouteA {
    border-radius: 5px;
    border: 3px solid #292341;
    padding: 8px 16px;
    color: #ffffff !important;
    text-decoration: none;
    background-color: #292341 !important;
    margin-top: 7px;
  }
  .getQouteA:hover {
    box-shadow: rgba(149, 157, 165, 0.5) 0px 4px 12px;
    color: #ecf6fd !important;
  }
}
.homeSlide1 {
  background: #d9d9d9;
  .leftSide {
    h2 {
      color: #242f9b;
      font-family: Inter;
      font-size: 72px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 60px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
    }
    h5 {
      color: #fff;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      animation: 0.7s ease-in 0s 1 slideInFromLeft;
    }
    h6 {
      color: #242f9b;
      font-family: Inter;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      animation: 0.9s ease-in 0s 1 slideInFromLeft;
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 130%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #292341;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1200px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #292341 !important;
  }

  .navbar-collapse {
    background-color: #3798d4dd;
    padding-left: 10px;
    margin-top: 41px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #ffffff !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    h2 {
      font-size: 48px;
    }
    h5 {
      font-size: 48px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 80px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      text-align: center;
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
  .headerBG {
    .navbar-brand {
      color: #242f9b;
      img {
        height: 35px;
      }
    }
  }
}
</style>
