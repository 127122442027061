<template>
  <div class="RightDeals">
    <div class="container-flued">
      <div class="container">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-4">
            <img src="@/assets/galaxy.jpg" class="fullWidthImg" />
          </div>
          <div class="col-lg-6">
            <h4>
              We develop Brands and Digital endeavors to Transform and Grow
              Online
            </h4>
            <p class="fw500">
              We develop exceptional IT solutions.<br />
              Our relentless quest of perfection yields outstanding outcomes.
            </p>
            <p>
              We strive for perfection, and we instill this in every employee
              that works on a Clouds92 Technologies, IT Solutions project. Our
              IT assistance offerings are tailored to your company's particular
              requirements, whether they are office-based, hybrid, or remote. We
              adapt with you as a business, guaranteeing you have the necessary
              amount of technical support to reach your commercial objectives.
            </p>
          </div>
          <div class="col-lg-1"></div>
        </div>
        <!--/row-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevelopBrands',
  props: {
    msg: String,
  },
  methods: {
    goto(selectedName) {
      this.$router.push({ name: selectedName, replace: true });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fullWidthImg {
  width: 100%;
}
.RightDeals {
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;
  h4 {
    color: #070d59;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 107.143% */
    margin-top: 20px;
  }
  p {
    color: #070d59;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 137.5% */
  }
  .zCard {
    margin: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 52, 120, 0.26);
    background: #73717b;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      display: inline-block;
    }
    h5 {
      color: #ffc93c;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: capitalize;
      margin-top: 10px;
    }
    a {
      border-radius: 8px;
      border: 2px solid #ffc93c;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}
.fw500 {
  font-weight: 600 !important;
}
@media screen and (max-width: 1300px) {
  .RightDeals {
    h3 {
      font-size: 24px;
    }
    h4 {
      font-size: 32px;
      margin-top: 0px;
    }
    h5 {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 992px) {
  .RightDeals {
    h3 {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: left;
    }
    h4 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    h5 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
</style>
