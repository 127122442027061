<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 leftSide">
          <p class="fcolor text-justify">
            One of our top goals at Clouds92technologies, available at
            <a href="https://www.clouds92tech.com/"
              >https://www.clouds92tech.com/</a
            >, is the privacy of our users. This Privacy Policy document
            describes the sorts of information gathered and recorded by
            Clouds92tech, as well as how we use it.
          </p>
          <p class="fcolor text-justify">
            Please contact us if you have any more concerns or need more
            information about our Privacy Policy.
          </p>
          <p class="fcolor text-justify">
            This Privacy Policy only applies to our online activities and is
            applicable to users to our website in relation to the information
            they shared and/or collected in Clouds92tech. This policy does not
            apply to any information gathered offline or through channels other
            than this website.
          </p>
          <h4 class="fcolor">How Clouds92tech Collects Your Personal Data:</h4>
          <p class="fcolor text-justify">
            Clouds92Technologies collects personal data such as candidate's
            information including Name, Address, Phone Number, Email etc.
            directly from you — as a candidate or a visitor — when you visit
            Clouds92Technologies website, ask for job-finding help services from
            Clouds92 Technologies, or when you contact directly a member of the
            Clouds92 Technologies team.
          </p>
          <h4 class="fcolor">Where You Information is Used:</h4>
          <ul class="fcolor">
            <li>Supply, manage, and uphold our website</li>
            <li>Enhance, customize, and broaden our website</li>
            <li>Recognize and assess how you using our website.</li>
            <li>Create new features, services, goods, and functionalities.</li>
            <li>
              Communicate with you directly or via one of our partners on a
              variety of topics, such as customer support, website updates and
              other information, and marketing and promotional activities.
            </li>
            <li>For promotional emails</li>
            <li>Detect and prevent fraud.</li>
          </ul>
          <h4 class="fcolor">Log files:</h4>
          <p class="fcolor text-justify">
            Internet protocol (IP) addresses, browser types, Internet Service
            Providers (ISPs), date and time stamps, referring/exit pages, and
            maybe click counts are among the data gathered by log files. These
            are not connected to any personally identifiable information. The
            data is gathered with the intention of evaluating patterns, managing
            the website, monitoring user behavior, and compiling demographic
            data.
          </p>
          <h4 class="fcolor">Third Party Privacy Policies:</h4>
          <p class="fcolor text-justify">
            The privacy statement of Clouds92tech does not extend to other
            websites or advertising. For more specific information, we so
            suggest that you review the individual Privacy Policies of these
            third-party ad servers. It might contain information about their
            policies and how to withdraw from particular choices. By using the
            settings on each browser, you may decide whether or not to allow
            cookies. You may find out more about managing cookies in particular
            web browsers by visiting the websites of those browsers.
          </p>
          <h4 class="fcolor">Third Party Advertiser’s Privacy Policy:</h4>
          <p class="fcolor text-justify">
            Please take note that Clouds92tech is not in charge of or has access
            to these third-party advertiser cookies. Technologies including
            cookies, JavaScript, and Web Beacons are used by third-party ad
            servers and ad networks in their adverts and links to Clouds92tech.
            These technologies are sent straight to users' browsers. When this
            happens, they automatically get your IP address. These technologies
            are employed to track how well their advertising campaigns are
            working and/or to target the advertisements you see on websites you
            visit.
          </p>
          <h4 class="fcolor">Children's Information:</h4>
          <p class="fcolor text-justify">
            Our goal also includes providing additional security Children when
            they use the internet. We advocate for parents and guardians to
            watch over, engage in, and/or supervise their children's internet
            activities. Under no circumstances does Clouds92tech knowingly
            obtain personally identifiable information from minors younger than
            18. We strongly advise you to get in touch with us right away if you
            believe your child submitted this kind of material on our website.
            We will work hard to get the information removed from our database
            as soon as possible.
          </p>
          <h4 class="fcolor">Contacting Us:</h4>
          <p class="fcolor text-justify">
            If you have any queries or any information you want related to our
            privacy policy, contact us here info@clouds92tech.com.<br /><br />
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'PrivacyDesc',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.homeSlide1 {
  background: rgba(242, 242, 242, 0.6);
  padding-bottom: 40px;
  .leftSide {
    padding-top: 40px;
    h2 {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 40px;
      animation: 0.5s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    h5 {
      color: #292341;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      /* animation: 0.7s ease-in 0s 1 slideInFromLeft; */
    }
    p {
      color: #292341;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      a {
        color: rgba(226, 184, 66, 0.91);
      }
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
ul {
  li {
    font-weight: 400;
  }
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 18px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
