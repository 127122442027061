<template>
  <div class="container-flued homeSlide1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 leftSide">
          <h5>Who We Are</h5>
          <p>
            Being a Leading on-demand IT software Development Company. Clouds92
            Technologies brings years of expertise in the software development
            industry. We have an excellent team of experts constantly strive to
            push limits to develop top-notch IT solutions. We're dedicated to
            the greater good that involves upholding the right to privacy and
            security. Our workforce performs nonstop to accomplish this goal.
          </p>
          <h6>Clouds92 Technologies In Numbers</h6>
          <div class="row">
            <div class="col-lg-3 yib">
              <img src="@/assets/about-1.png" />
              <div class="h6">Years In Business</div>
              <div class="h5">5 &nbsp; +</div>
            </div>
            <div class="col-lg-3 yib">
              <img src="@/assets/about-2.png" />
              <div class="h6">Clients Fell in Love with Us</div>
              <div class="h5">10 &nbsp; %</div>
            </div>
            <div class="col-lg-3 yib">
              <img src="@/assets/about-3.png" />
              <div class="h6">Skilled Employees</div>
              <div class="h5">5 &nbsp; +</div>
            </div>
            <div class="col-lg-3 yib">
              <img src="@/assets/about-4.png" />
              <div class="h6">Completed Projects</div>
              <div class="h5">100 &nbsp; +</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-flued homeSlide1">
    <div class="row">
      <div class="col-12">
        <h2>"Stay Prepared, Stay Protected</h2>
        <h5>Insurance for Life's Uncertainties"</h5>
        <h6>Your Shield in an Uncertain World"</h6>
        <a href="#" class="yellowButton">Get Your Quote</a>
      </div>
    </div>
  </div> -->
  <!--container-->
</template>

<script>
export default {
  name: 'AboutSlider',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lan="scss">
.preHdeader {
  background: #242f9b !important;
  color: #fff;
  height: 50px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  svg {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.headerBG {
  background: linear-gradient(
    99deg,
    #292341 -5.28%,
    rgba(0, 52, 120, 0.26) 149.21%
  ) !important;
  height: 80px;
  .navbar-brand {
    color: #242f9b;
  }
  .navbar-nav {
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      a {
        color: #242f9b;
      }
      a:hover {
        color: rgba(255, 201, 60, 1);
      }
      a::after {
        color: rgba(255, 201, 60, 1);
      }
    }
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-top: 26px;
  }

  .dropdown-item {
    color: #fff !important;
    height: 40px;
    line-height: 33px;
  }
  .dropdown-item:hover {
    background-color: #00000040;
    color: rgba(255, 201, 60, 1) !important;
    transition-duration: 0.5s;
  }
  .getQouteA {
    border-radius: 8px;
    border: 3px solid #ffc93c;
    padding: 8px 16px;
    color: #242f9b;
    text-decoration: none;
  }
}
.homeSlide1 {
  background-image: url('@/assets/about-us.jpg');
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  .leftSide {
    h6 {
      color: #f5f5f5;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2),
        0px -4px 5px rgba(255, 255, 255, 0.2);
      margin-top: 40px;
      margin-bottom: 30px;
      animation: 1.5s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    h5 {
      color: #f5f5f5;
      font-family: Futura Hv BT;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      animation: 0.7s ease-in 0s 1 slideInFromLeft;
      font-family: Inter;
      text-align: center;
      margin-top: 80px;
    }
    p {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      animation: 1s ease-in 0s 1 slideInFromLeft;
      text-align: center;
    }
    .row {
      margin-right: 0px;
    }
    .yellowButton {
      width: 220px;
      margin: 0 auto;
      display: block;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
      animation: 1.1s ease-in 0s 1 slideInFromLeft;
    }
  }
  .rightSide {
    .circle1 {
      animation: 0.8s ease-in 0s 1 slideInFromLeft;
      margin-top: 40px;
      img {
        width: 100%;
        /* filter: drop-shadow(0 0 0.75rem #00000099); */
      }
    }
    .circle2 {
      animation: 1s ease-in 0s 1 slideInFromLeft;
      margin-top: 80px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0 #00000099);
      }
    }
    .circle3 {
      animation: 1.2s ease-in 0s 1 slideInFromLeft;
      margin-top: -80px;
      margin-left: -30px;
      img {
        width: 100%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
    .circle4 {
      animation: 1.4s ease-in 0s 1 slideInFromLeft;
      margin-top: -165px;
      margin-left: -20px;
      img {
        width: 120%;
        filter: drop-shadow(0 0 0.75rem #00000099);
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.yellowButton {
  padding: 14px 16px;
  border-radius: 8px;
  background: #ffc93c;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
@media screen and (min-width: 1500px) {
  .homeSlide1 {
    h5 {
      text-align: center;
    }
  }
}
@media screen and (max-width: 1300px) {
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
      }
      h5 {
        font-size: 32px;
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -144px !important;
    }
  }
}
.yib {
  text-align: center;
  animation: 1.5s ease-in 0s 1 slideInFromLeft;
  img {
    height: 80px;
    text-align: center;
  }
  .h6 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  .h5 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
}
@media screen and (min-width: 992px) {
  .headerBG {
    .navbar-nav {
      width: 100%;
      .nav-item {
        width: 20%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        .getQouteA {
          font-size: 14px;
          margin-top: 0px;
          display: inline-block;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: #242f9b !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .getQouteA {
    width: 160px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #ffc93c;
  }

  .navbar-collapse {
    background-color: #242f9bee;
    padding-left: 10px;
    margin-top: 21px;
    .nav-item {
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
      a {
        color: #ffffff !important;
      }
      .getQouteA {
        width: 98%;
        text-align: center;
        display: inline-block;
        color: #242f9b !important;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }
  }
  .homeSlide1 {
    .leftSide {
      h2 {
        font-size: 48px;
        margin-top: 40px;
      }
      h5 {
        font-size: 32px;
        width: 100%;
        /* text-align: center; */
      }
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .yib {
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 540px) {
  .hideOnMobile {
    display: none;
  }
  .homeSlide1 {
    h2 {
      font-size: 32px;
      margin-top: 40px;
    }
    h5 {
      font-size: 32px;
      width: 100%;
      /* text-align: center; */
    }
    h6 {
      font-size: 24px;
    }
  }
  .rightSide {
    .circle4 {
      margin-top: -30% !important;
      img {
        width: 100%;
      }
    }
  }
}
</style>
